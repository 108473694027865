// 角色
const roleList = [
  {
    id: 1,
    name: '超级管理员',
    remark: '该权限一般分配给系统管理员，或用于开发人员进行系统调试',
    auths: ['manager.browse', 'manager.create', 'manager.edit', 'manager.delete'],
  },
  {
    id: 2,
    name: '测试角色',
    remark: '',
    auths: ['test1'],
  },
]

export default [
  {
    url: '/mock/role/list',
    method: 'get',
    response: ({ query }: any) => {
      const { name, from, limit } = query
      const list = roleList.filter((item) => {
        return name ? item.name.includes(name) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: 'ok',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/role/detail',
    method: 'get',
    response: ({ query }: any) => {
      const info = roleList.filter(item => item.id === ~~query.id)
      return {
        message: 'ok',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/role/create',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/role/edit',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/role/delete',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]
