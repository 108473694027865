import Mock from 'mockjs'
import area from '@/components/PcasCascader/pcas-code.json'

const Random = Mock.Random

// 表单展示模式
const formModeList: any[] = []
for (let i = 0; i < 50; i++) {
  formModeList.push(Mock.mock({
    id: '@id',
    title: '@ctitle(10, 20)',
  }))
}
const formModeMock = [
  {
    url: '/mock/pages_example/form_mode/list',
    method: 'get',
    response: (option: any) => {
      const { title, from, limit } = option.query
      const list = formModeList.filter((item) => {
        return title ? item.title.includes(title) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/form_mode/detail',
    method: 'get',
    response: (option: any) => {
      const info = formModeList.filter(item => item.id === option.query.id)
      return {
        message: '',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/pages_example/form_mode/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/form_mode/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/form_mode/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]

// 导航
const menuList = [
  {
    id: 1,
    parentId: 0,
    meta: {
      title: '演示',
      icon: 'uim:box',
      activeIcon: '',
      auth: [],
    },
    auths: [],
    children: [
      {
        id: 2,
        parentId: 1,
        path: '/multilevel_menu_example',
        redirect: '/multilevel_menu_example/page',
        component: 'Layout',
        name: 'multilevelMenuExample',
        meta: {
          title: '多级导航',
          icon: 'heroicons-solid:menu-alt-3',
          activeIcon: '',
          defaultOpened: false,
          permanent: false,
          auth: [],
          sidebar: true,
          breadcrumb: true,
          activeMenu: '',
          cache: [],
          noCache: [],
          badge: '',
          link: '',
          iframe: '',
          copyright: false,
          paddingBottom: '0px',
        },
        auths: [
          {
            name: '测试权限1',
            value: 'test1',
          },
          {
            name: '测试权限2',
            value: 'test2',
          },
          {
            name: '测试权限3',
            value: 'test3',
          },
        ],
        children: [
          {
            id: 3,
            parentId: 2,
            path: 'page',
            redirect: '',
            component: 'multilevel_menu_example/page.vue',
            name: 'multilevelMenuExample1',
            meta: {
              title: '导航1',
              icon: '',
              activeIcon: '',
              defaultOpened: false,
              permanent: false,
              auth: [],
              sidebar: true,
              breadcrumb: true,
              activeMenu: '',
              cache: [],
              noCache: [],
              badge: '',
              link: '',
              iframe: '',
              copyright: false,
              paddingBottom: '0px',
            },
            auths: [],
          },
          {
            id: 4,
            parentId: 2,
            path: 'level2',
            name: 'multilevelMenuExample2',
            redirect: '/multilevel_menu_example/level2/page',
            meta: {
              title: '导航2',
              icon: '',
              activeIcon: '',
              defaultOpened: false,
              permanent: false,
              auth: [],
              sidebar: true,
              breadcrumb: true,
              activeMenu: '',
              cache: [],
              noCache: [],
              badge: '',
              link: '',
              iframe: '',
              copyright: false,
              paddingBottom: '0px',
            },
            auths: [],
            children: [
              {
                id: 5,
                parentId: 4,
                path: 'page',
                name: 'multilevelMenuExample2-1',
                component: 'multilevel_menu_example/level2/page.vue',
                meta: {
                  title: '导航2-1',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: [],
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: '',
                  copyright: false,
                  paddingBottom: '0px',
                },
                auths: [],
              },
              {
                id: 6,
                parentId: 4,
                path: 'level3',
                name: 'multilevelMenuExample2-2',
                redirect: '/multilevel_menu_example/level2/level3/page1',
                meta: {
                  title: '导航2-2',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: [],
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: '',
                  copyright: false,
                  paddingBottom: '0px',
                },
                auths: [],
                children: [
                  {
                    id: 7,
                    parentId: 6,
                    path: 'page1',
                    name: 'multilevelMenuExample2-2-1',
                    component: 'multilevel_menu_example/level2/level3/page1.vue',
                    meta: {
                      title: '导航2-2-1',
                      icon: '',
                      activeIcon: '',
                      defaultOpened: false,
                      permanent: false,
                      auth: [],
                      sidebar: true,
                      breadcrumb: true,
                      activeMenu: '',
                      cache: [],
                      noCache: [],
                      badge: '',
                      link: '',
                      iframe: '',
                      copyright: false,
                      paddingBottom: '0px',
                    },
                    auths: [],
                  },
                  {
                    id: 8,
                    parentId: 6,
                    path: 'page2',
                    name: 'multilevelMenuExample2-2-2',
                    component: 'multilevel_menu_example/level2/level3/page2.vue',
                    meta: {
                      title: '导航2-2-2',
                      icon: '',
                      activeIcon: '',
                      defaultOpened: false,
                      permanent: false,
                      auth: [],
                      sidebar: true,
                      breadcrumb: true,
                      activeMenu: '',
                      cache: [],
                      noCache: [],
                      badge: '',
                      link: '',
                      iframe: '',
                      copyright: false,
                      paddingBottom: '0px',
                    },
                    auths: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 9,
        parentId: 1,
        path: '/link',
        component: 'Layout',
        redirect: '/link/iframe',
        name: 'externalLinkExample',
        meta: {
          title: '外链',
          icon: 'ri:external-link-fill',
          activeIcon: '',
          defaultOpened: false,
          permanent: false,
          auth: [],
          sidebar: true,
          breadcrumb: true,
          activeMenu: '',
          cache: [],
          noCache: [],
          badge: '',
          link: '',
          iframe: '',
          copyright: false,
          paddingBottom: '0px',
        },
        auths: [],
        children: [
          {
            id: 10,
            parentId: 9,
            path: 'iframe',
            redirect: '/link/iframe/iframeVue',
            meta: {
              title: '内嵌 Iframe',
              icon: '',
              activeIcon: '',
              defaultOpened: false,
              permanent: false,
              auth: [],
              sidebar: true,
              breadcrumb: true,
              activeMenu: '',
              cache: [],
              noCache: [],
              badge: '',
              link: '',
              iframe: '',
              copyright: false,
              paddingBottom: '0px',
            },
            auths: [],
            children: [
              {
                id: 11,
                parentId: 10,
                path: 'iframeVue',
                redirect: '',
                name: 'linkExampleIframeVue',
                meta: {
                  title: 'Vue 官方文档',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: true,
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: 'https://cn.vuejs.org/',
                  copyright: false,
                },
                auths: [],
              },
              {
                id: 12,
                parentId: 10,
                path: 'iframeElement',
                redirect: '',
                name: 'linkExampleIframeElement',
                meta: {
                  title: 'Element Plus 官方文档',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: true,
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: 'https://element-plus.gitee.io/zh-CN/',
                  copyright: false,
                },
                auths: [],
              },
              {
                id: 13,
                parentId: 10,
                path: 'iframeVueRouter',
                redirect: '',
                name: 'linkExampleIframeVueRouter',
                meta: {
                  title: 'Vue-Router 官方文档',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: true,
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: 'https://router.vuejs.org/zh/',
                  copyright: false,
                },
                auths: [],
              },
              {
                id: 14,
                parentId: 10,
                path: 'iframeVuePinia',
                redirect: '',
                name: 'linkExampleIframeVuePinia',
                meta: {
                  title: 'Pinia 官方文档',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: true,
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: 'https://pinia.vuejs.org/zh/',
                  copyright: false,
                },
                auths: [],
              },
            ],
          },
          {
            id: 15,
            parentId: 9,
            path: 'window',
            redirect: '/link/window/gitee',
            meta: {
              title: '新窗口打开',
              icon: '',
              activeIcon: '',
              defaultOpened: false,
              permanent: false,
              auth: [],
              sidebar: true,
              breadcrumb: true,
              activeMenu: '',
              cache: [],
              noCache: [],
              badge: '',
              link: '',
              iframe: '',
              copyright: false,
              paddingBottom: '0px',
            },
            auths: [],
            children: [
              {
                id: 16,
                parentId: 15,
                path: 'gitee',
                redirect: '',
                name: 'linkExampleWindowGitee',
                meta: {
                  title: 'Gitee 仓库',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: [],
                  noCache: [],
                  badge: '',
                  link: 'https://gitee.com/hooray/fantastic-admin',
                  iframe: '',
                  copyright: false,
                  paddingBottom: '0px',
                },
                auths: [],
              },
              {
                id: 17,
                parentId: 15,
                path: 'github',
                redirect: '',
                name: 'linkExampleWindowGithub',
                meta: {
                  title: 'Github 仓库',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [],
                  sidebar: true,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: [],
                  noCache: [],
                  badge: '',
                  link: 'https://github.com/hooray/fantastic-admin',
                  iframe: '',
                  copyright: false,
                  paddingBottom: '0px',
                },
                auths: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 50,
    parentId: 0,
    meta: {
      title: '页面',
      i18n: '',
      icon: 'ri:pages-line',
      activeIcon: '',
      auth: [],
    },
    auths: [],
    children: [
      {
        id: 51,
        parentId: 50,
        path: '/pages_example/general',
        component: 'Layout',
        redirect: '/pages_example/general/manager',
        name: 'pagesExampleGeneral',
        meta: {
          title: '通用',
          icon: '',
          activeIcon: '',
          defaultOpened: false,
          permanent: false,
          auth: [],
          sidebar: true,
          breadcrumb: true,
          activeMenu: '',
          cache: [],
          noCache: [],
          badge: '',
          link: '',
          iframe: '',
          copyright: false,
          paddingBottom: '0px',
        },
        auths: [],
        children: [
          {
            id: 52,
            parentId: 51,
            path: 'manager',
            name: 'pagesExampleGeneralManager',
            redirect: '/pages_example/general/manager',
            meta: {
              title: '管理员管理',
              icon: '',
              activeIcon: '',
              defaultOpened: false,
              permanent: false,
              auth: [],
              sidebar: true,
              breadcrumb: true,
              activeMenu: '',
              cache: [],
              noCache: [],
              badge: '',
              link: '',
              iframe: '',
              copyright: false,
              paddingBottom: '0px',
            },
            auths: [
              {
                name: '浏览',
                value: 'manager.browse',
              },
              {
                name: '新增',
                value: 'manager.create',
              },
              {
                name: '编辑',
                value: 'manager.edit',
              },
              {
                name: '删除',
                value: 'manager.delete',
              },
            ],
            children: [
              {
                id: 53,
                parentId: 52,
                path: '',
                name: 'pagesExampleGeneralManagerList',
                component: 'pages_example/manager/list.vue',
                meta: {
                  title: '管理员列表',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [
                    'manager.browse',
                  ],
                  sidebar: false,
                  breadcrumb: false,
                  activeMenu: '',
                  cache: [
                    'pagesExampleGeneralManagerCreate',
                    'pagesExampleGeneralManagerEdit',
                  ],
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: '',
                  copyright: false,
                  paddingBottom: '0px',
                },
                auths: [],
              },
              {
                id: 53,
                parentId: 52,
                path: 'detail',
                name: 'pagesExampleGeneralManagerCreate',
                component: 'pages_example/manager/detail.vue',
                meta: {
                  title: '新增管理员',
                  icon: '',
                  activeIcon: '/pages_example/general/manager',
                  defaultOpened: false,
                  permanent: false,
                  auth: [
                    'manager.create',
                  ],
                  sidebar: false,
                  breadcrumb: true,
                  activeMenu: '',
                  cache: [],
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: '',
                  copyright: false,
                  paddingBottom: '0px',
                },
                auths: [],
              },
              {
                id: 54,
                parentId: 52,
                path: 'detail/:id',
                name: 'pagesExampleGeneralManagerEdit',
                component: 'pages_example/manager/detail.vue',
                meta: {
                  title: '编辑管理员',
                  icon: '',
                  activeIcon: '',
                  defaultOpened: false,
                  permanent: false,
                  auth: [
                    'manager.edit',
                  ],
                  sidebar: false,
                  breadcrumb: true,
                  activeMenu: '/pages_example/general/manager',
                  cache: [],
                  noCache: [],
                  badge: '',
                  link: '',
                  iframe: '',
                  copyright: false,
                  paddingBottom: '0px',
                },
                auths: [],
              },
            ],
          },
        ],
      },
    ],
  },
]
const menuMock = [
  {
    url: '/mock/pages_example/menu/list',
    method: 'get',
    response: () => {
      return {
        message: '',
        code: '200',
        data: menuList,
      }
    },
  },
  {
    url: '/mock/pages_example/menu/detail',
    method: 'get',
    response: (option: any) => {
      function findMenu(list: any, id: number): any {
        for (const i in list) {
          if (list[i].id === id) {
            const retn = {
              ...list[i],
            }
            delete retn.children
            return retn
          }
          else if (list[i].children) {
            const temp = findMenu(list[i].children, id)
            if (temp) {
              return temp
            }
          }
        }
      }
      const info = findMenu(menuList, ~~option.query.id)
      return {
        message: '',
        code: '200',
        data: info,
      }
    },
  },
  {
    url: '/mock/pages_example/menu/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/menu/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/menu/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]

// 管理员
const managerList: any[] = []
for (let i = 0; i < 50; i++) {
  managerList.push(Mock.mock({
    id: '@id',
    account: '@first',
    name: '@cname',
    sex: Random.natural(0, 2),
    mobile: /1\d{10}/,
    status: '@boolean',
  }))
}
const managerMock = [
  {
    url: '/mock/pages_example/manager/list',
    method: 'get',
    response: ({ query }: any) => {
      const { account, name, mobile, sex, from, limit } = query
      let list = managerList.filter((item) => {
        return account ? item.account.includes(account) : true
      })
      list = list.filter((item) => {
        return name ? item.name.includes(name) : true
      })
      list = list.filter((item) => {
        return mobile ? item.mobile === mobile : true
      })
      list = list.filter((item) => {
        return sex ? item.sex === sex : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/manager/detail',
    method: 'get',
    response: ({ query }: any) => {
      const info = managerList.filter(item => item.id === query.id)
      return {
        message: '',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/pages_example/manager/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/manager/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/manager/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/manager/password/reset',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/manager/change/status',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/manager/check',
    method: 'get',
    response: (option: any) => {
      const { key, value, id } = option.query
      let exist = false
      exist = managerList.some((item) => {
        let flag
        switch (key) {
          case 'account':
            if (item.account === value && (id ? id !== item.id : true)) {
              flag = true
            }
            break
          case 'mobile':
            if (item.mobile === value && (id ? id !== item.id : true)) {
              flag = true
            }
            break
        }
        return flag
      })
      return {
        message: '',
        code: '200',
        data: {
          exist,
        },
      }
    },
  },
]

// 部门&职位
const departmentjobList = [
  {
    id: 1,
    title: '技术部',
    jobList: [
      { id: 1, title: '前端开发' },
      { id: 2, title: '接口开发' },
      { id: 3, title: 'App开发' },
    ],
  },
  {
    id: 2,
    title: '设计部',
    jobList: [
      { id: 4, title: '网页设计' },
      { id: 5, title: '视觉设计' },
    ],
  },
  {
    id: 3,
    title: '运营部',
    jobList: [
      { id: 6, title: '用户运营' },
      { id: 7, title: '产品运营' },
      { id: 8, title: '内容运营' },
    ],
  },
]
const departmentjobMock = [
  {
    url: '/mock/pages_example/department/list',
    method: 'get',
    response: (option: any) => {
      const { title, from, limit } = option.query
      const list = departmentjobList.filter((item) => {
        return title ? item.title.includes(title) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/department/detail',
    method: 'get',
    response: (option: any) => {
      const info = departmentjobList.filter(item => item.id === ~~option.query.id)
      return {
        message: '',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/pages_example/department/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/department/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/department/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/job/list',
    method: 'get',
    response: (option: any) => {
      const { department_id, title, from, limit } = option.query
      let jobList: any[] = []
      for (const i in departmentjobList) {
        if (departmentjobList[i].id === ~~department_id) {
          jobList = departmentjobList[i].jobList
        }
      }
      const list = jobList.filter((item) => {
        return title ? item.title.includes(title) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/job/detail',
    method: 'get',
    response: (option: any) => {
      let jobList: any[] = []
      for (const i in departmentjobList) {
        if (departmentjobList[i].id === ~~option.query.department_id) {
          jobList = departmentjobList[i].jobList
        }
      }
      const info = jobList.filter(item => item.id === ~~option.query.id)
      return {
        message: '',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/pages_example/job/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/job/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/job/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]

// 角色
const roleList = [
  {
    id: 1,
    name: '超级管理员',
    remark: '该权限一般分配给系统管理员，或用于开发人员进行系统调试',
    auths: ['manager.browse', 'manager.create', 'manager.edit', 'manager.delete'],
  },
  {
    id: 2,
    name: '测试角色',
    remark: '',
    auths: ['test1'],
  },
]
const roleMock = [
  {
    url: '/mock/pages_example/role/list',
    method: 'get',
    response: (option: any) => {
      const { name, from, limit } = option.query
      const list = roleList.filter((item) => {
        return name ? item.name.includes(name) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/role/detail',
    method: 'get',
    response: (option: any) => {
      const info = roleList.filter(item => item.id === ~~option.query.id)
      return {
        message: '',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/pages_example/role/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/role/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/role/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]

// 字典
const dictionaryList = [
  {
    id: 1,
    label: '这是一个超级超级超级超级长的字典标题',
    code: 'test',
    items: [
      {
        id: 1,
        name: '测试',
        value: '1',
        enable: true,
      },
    ],
  },
  {
    id: 2,
    label: '性别',
    code: 'sex',
    items: [
      {
        id: 2,
        name: '女',
        value: '0',
        enable: true,
      },
      {
        id: 3,
        name: '男',
        value: '1',
        enable: true,
      },
      {
        id: 4,
        name: '保密',
        value: '2',
        enable: true,
      },
    ],
  },
  {
    id: 3,
    label: '用户',
    code: 'user',
    items: [],
    children: [
      {
        id: 4,
        label: '证件',
        code: 'user_card',
        items: [
          {
            id: 5,
            name: '居民身份证',
            value: '1',
            enable: true,
          },
          {
            id: 6,
            name: '出生证',
            value: '2',
            enable: false,
          },
          {
            id: 7,
            name: '护照',
            value: '3',
            enable: true,
          },
        ],
      },
      {
        id: 5,
        label: '学历',
        code: 'user_education',
        items: [
          {
            id: 8,
            name: '初中及以下',
            value: '1',
            enable: true,
          },
          {
            id: 9,
            name: '高中',
            value: '2',
            enable: true,
          },
          {
            id: 10,
            name: '大学',
            value: '3',
            enable: true,
          },
        ],
      },
    ],
  },
]
const dictionaryMock = [
  {
    url: '/mock/pages_example/dictionary/list',
    method: 'get',
    response: () => {
      function getDictionaryTree(list: any) {
        const tree = []
        for (const i in list) {
          const item: {
            [key: string]: any
          } = {
            id: list[i].id,
            label: list[i].label,
            code: list[i].code,
          }
          if (list[i].children) {
            item.children = getDictionaryTree(list[i].children)
          }
          tree.push(item)
        }
        return tree
      }
      const pageList = getDictionaryTree(dictionaryList)
      return {
        message: '',
        code: '200',
        data: pageList,
      }
    },
  },
  {
    url: '/mock/pages_example/dictionary/detail',
    method: 'get',
    response: (option: any) => {
      function findDictionary(list: any, parentId: number | '', id: number): any {
        for (const i in list) {
          if (list[i].id === id) {
            return {
              parentId,
              id: list[i].id,
              name: list[i].label,
              code: list[i].code,
            }
          }
          else if (list[i].children) {
            const temp = findDictionary(list[i].children, list[i].id, id)
            if (temp) {
              return temp
            }
          }
        }
      }
      const info = findDictionary(dictionaryList, '', ~~option.query.id)
      return {
        message: '',
        code: '200',
        data: info,
      }
    },
  },
  {
    url: '/mock/pages_example/dictionary/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
        data: Random.natural(100, 1000),
      },
    },
  },
  {
    url: '/mock/pages_example/dictionary/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/dictionary/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/dictionary/item/list',
    method: 'get',
    response: (option: any) => {
      const { title, dictionaryId, from, limit } = option.query
      function findDictionary(list: any, dictionaryId: number): any {
        for (const i in list) {
          if (list[i].id === dictionaryId) {
            return list[i].items ?? []
          }
          if (list[i].children) {
            return findDictionary(list[i].children, dictionaryId)
          }
        }
      }
      let list: any[] = findDictionary(dictionaryList, ~~dictionaryId) ?? []
      list = list.filter((item) => {
        return title ? item.name.includes(title) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/dictionary/item/detail',
    method: 'get',
    response: (option: any) => {
      function findDictionaryItem(list: any, id: number): any {
        for (const i in list) {
          if (list[i].items.some((item: any) => item.id === id)) {
            return list[i].items.find((item: any) => item.id === id)
          }
          if (list[i].children) {
            return findDictionaryItem(list[i].children, id)
          }
        }
      }
      const info = findDictionaryItem(dictionaryList, ~~option.query.id)
      return {
        message: '',
        code: '200',
        data: info,
      }
    },
  },
  {
    url: '/mock/pages_example/dictionary/item/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/dictionary/item/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/dictionary/item/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/dictionary/item/delete/multi',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/dictionary/item/change/enable',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]

// 表格
const tableList = [
  {
    id: 1,
    name: '商品列表',
    code: 'goods_list',
    border: true,
    stripe: true,
    header: true,
    columns: [
      {
        name: '商品名称',
        field: 'name',
        width: '',
        align: 'left',
        sortable: false,
        fixed: '',
        enable: true,
      },
      {
        name: '商品编码',
        field: 'code',
        width: '150',
        align: 'center',
        sortable: false,
        fixed: 'left',
        enable: true,
      },
      {
        name: '商品价格',
        field: 'price',
        width: '100',
        align: 'left',
        sortable: false,
        fixed: '',
        enable: true,
      },
      {
        name: '入库时间',
        field: 'datetime',
        width: '200',
        align: 'center',
        sortable: true,
        fixed: '',
        enable: true,
      },
    ],
  },
]
const tableMock = [
  {
    url: '/mock/pages_example/table/list',
    method: 'get',
    response: ({ query }: any) => {
      const { name, from, limit } = query
      const list = tableList.filter((item) => {
        return name ? item.name.includes(name) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/table/detail',
    method: 'get',
    response: ({ query }: any) => {
      const info = tableList.filter(item => item.id === ~~query.id)
      return {
        message: '',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/pages_example/table/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/table/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/table/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/table/preview',
    method: 'get',
    response: ({ query }: any) => {
      const { id, code, from, limit } = query
      const info = tableList.filter((item) => {
        return item.id === ~~id || item.code === code
      })
      const list = [
        { name: '商品1', code: '001', price: '100', datetime: '2020-01-01 00:01:00' },
        { name: '商品2', code: '002', price: '100', datetime: '2020-01-01 00:02:00' },
        { name: '商品3', code: '003', price: '100', datetime: '2020-01-01 00:03:00' },
        { name: '商品4', code: '004', price: '100', datetime: '2020-01-01 00:04:00' },
        { name: '商品5', code: '005', price: '100', datetime: '2020-01-01 00:05:00' },
        { name: '商品6', code: '006', price: '100', datetime: '2020-01-01 00:06:00' },
        { name: '商品7', code: '007', price: '100', datetime: '2020-01-01 00:07:00' },
        { name: '商品8', code: '008', price: '100', datetime: '2020-01-01 00:08:00' },
        { name: '商品9', code: '009', price: '100', datetime: '2020-01-01 00:09:00' },
        { name: '商品10', code: '010', price: '100', datetime: '2020-01-01 00:10:00' },
        { name: '商品11', code: '011', price: '100', datetime: '2020-01-01 00:11:00' },
        { name: '商品12', code: '012', price: '100', datetime: '2020-01-01 00:12:00' },
        { name: '商品13', code: '013', price: '100', datetime: '2020-01-01 00:13:00' },
        { name: '商品14', code: '014', price: '100', datetime: '2020-01-01 00:14:00' },
        { name: '商品15', code: '015', price: '100', datetime: '2020-01-01 00:15:00' },
        { name: '商品16', code: '016', price: '100', datetime: '2020-01-01 00:16:00' },
        { name: '商品17', code: '017', price: '100', datetime: '2020-01-01 00:17:00' },
        { name: '商品18', code: '018', price: '100', datetime: '2020-01-01 00:18:00' },
        { name: '商品19', code: '019', price: '100', datetime: '2020-01-01 00:19:00' },
        { name: '商品20', code: '020', price: '100', datetime: '2020-01-01 00:20:00' },
      ]
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
          tableInfo: id ? info[0] : undefined,
        },
      }
    },
  },
]

// 运费模版
const deliveryList: any[] = []
for (let i = 0; i < 50; i++) {
  const address = []
  const p_index = Random.natural(0, area.length - 1)
  address.push(area[p_index].name)
  const c_index = Random.natural(0, area[p_index].children.length - 1)
  address.push(area[p_index].children[c_index].name)
  const a_index = Random.natural(0, area[p_index].children[c_index].children.length - 1)
  address.push(area[p_index].children[c_index].children[a_index].name)
  deliveryList.push(Mock.mock({
    id: '@id',
    title: '@ctitle(5, 10)',
    address,
    method: Random.natural(1, 2),
    first_step: Random.natural(1, 5),
    first_price: Random.natural(1, 5),
    continued_step: Random.natural(5, 10),
    continued_price: Random.natural(5, 10),
    infos: [
      {
        list: ['43', '50'],
        first_step: Random.natural(1, 5),
        first_price: Random.natural(1, 5),
        continued_step: Random.natural(5, 10),
        continued_price: Random.natural(5, 10),
      },
    ],
    status: '@boolean',
  }))
}
const deliveryMock = [
  {
    url: '/mock/pages_example/delivery/list',
    method: 'get',
    response: (option: any) => {
      const { title, from, limit } = option.query
      const list = deliveryList.filter((item) => {
        return title ? item.title.includes(title) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: '',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/pages_example/delivery/detail',
    method: 'get',
    response: (option: any) => {
      const info = deliveryList.filter(item => item.id === option.query.id)
      return {
        message: '',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/pages_example/delivery/create',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/delivery/edit',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/delivery/delete',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/pages_example/delivery/change/status',
    method: 'post',
    response: {
      message: '',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]

export default [
  ...formModeMock,
  ...menuMock,
  ...managerMock,
  ...departmentjobMock,
  ...roleMock,
  ...dictionaryMock,
  ...tableMock,
  ...deliveryMock,
]
