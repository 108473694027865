import './utils/system.copyright'
import ElementPlus from 'element-plus'
import App from './App.vue'
import pinia from './store'
import router from './router'
import { useI18n } from './locales'

// 自定义指令
import directive from '@/utils/directive'

// 错误日志上报
import errorLog from '@/utils/error.log'

// 加载 svg 图标
import 'virtual:svg-icons-register'

// 加载 iconify 图标
import { downloadAndInstall } from '@/iconify'
import icons from '@/iconify/index.json'

// 全局样式
import 'uno.css'
import '@/assets/styles/globals.scss'

const app = createApp(App)
app.use(ElementPlus)
app.use(pinia)
app.use(router)
useI18n(app)
directive(app)
errorLog(app)
if (icons.useType === 'offline') {
  for (const info of icons.collections) {
    downloadAndInstall(info)
  }
}

app.mount('#app')
