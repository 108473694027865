import CryptoJS from 'crypto-js'
import useSettingsStore from './settings'
import useTabbarStore from './tabbar'
import useRouteStore from './route'
import useMenuStore from './menu'
import router from '@/router'
import apiUser from '@/api/modules/user'
import storage from '@/utils/storage'
import api from '@/api'

const useUserStore = defineStore(
  // 唯一ID
  'user',
  () => {
    const settingsStore = useSettingsStore()
    const tabbarStore = useTabbarStore()
    const routeStore = useRouteStore()
    const menuStore = useMenuStore()

    let user = ref((storage.local.get('user') !== null && storage.local.get('user') !== 'undefined' && storage.local.get('user') !== '') ? JSON.parse(String(storage.local.get('user'))) : {})
    const token = ref(storage.local.get('token') ?? '')
    const failureTime = ref(storage.local.get('failureTime') ?? '')
    const permissions = ref<string[]>([])
    const isLogin = computed(() => {
      window.console.log('判断登录状态')
      let retn = false
      window.console.log(token.value)
      window.console.log(Number.parseInt(failureTime.value))
      if (token.value) {
        if (new Date().getTime() < Number.parseInt(failureTime.value) * 1000) {
          retn = true
        }
      }
      return retn
    })

    // 登录
    async function login(data: {
      account: string
      password: string
    }) {
      // 重新封装 将明文密码进行hash256加密
      const loginData = {
        userName: data.account,
        passWord: CryptoJS.SHA256(data.password).toString(),
      }
      // 发起登录请求
      const res = await api.post('internal/user/access/login', loginData)
      storage.local.set('user', JSON.stringify(res.data.user))
      storage.local.set('token', res.data.token)
      storage.local.set('failureTime', (res.data.failureTime / 1000).toString())
      user = ref(res.data.user)
      token.value = res.data.token
      failureTime.value = res.data.failureTime
      location.reload()
    }
    // 登出
    async function logout(redirect = router.currentRoute.value.fullPath) {
      // 更新最后登录时间
      // await api.post('/internal/user/access/logout')
      storage.local.remove('user')
      storage.local.remove('token')
      storage.local.remove('failureTime')
      user = ref({})
      token.value = ''
      failureTime.value = ''
      permissions.value = []
      tabbarStore.clean()
      routeStore.removeRoutes()
      menuStore.setActived(0)
      router.push({
        name: 'login',
        query: {
          ...(router.currentRoute.value.path !== '/' && router.currentRoute.value.name !== 'login' && { redirect }),
        },
      })
      location.reload()
    }
    // 获取权限
    async function getPermissions() {
      const res = await apiUser.permission()
      permissions.value = res.data.permissions
      return permissions.value
    }
    // 获取偏好设置
    async function getPreferences() {
      const res = await apiUser.preferences()
      settingsStore.updateSettings(JSON.parse(res.data.preferences || '{}'), true)
    }
    // 修改密码
    async function editPassword(data: {
      password: string
      newpassword: string
    }) {
      await apiUser.passwordEdit(data)
    }

    return {
      user,
      token,
      permissions,
      isLogin,
      login,
      logout,
      getPermissions,
      getPreferences,
      editPassword,
    }
  },
)

export default useUserStore
