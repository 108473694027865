import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/manage/organize',
  component: Layout,
  redirect: '/manage/organize/user',
  name: 'InternalOrganization',
  meta: {
    title: '组织',
    icon: 'ph:users-three-bold',
  },
  children: [
    {
      path: 'user',
      name: 'AuthorityManageUser',
      meta: {
        title: '员工管理',
        icon: 'ph:user',
      },
      children: [
        {
          path: '',
          name: 'AuthorityManageUserList',
          component: () => import('@/views/oa/user/list.vue'),
          meta: {
            title: '账号列表',
            sidebar: false,
            cache: ['AuthorityManageUserCreate', 'AuthorityManageUserEdit'],
          },
        },
        {
          path: 'create',
          name: 'AuthorityManageUserCreate',
          component: () => import('@/views/oa/user/detail.vue'),
          meta: {
            title: '新增账号',
            sidebar: false,
            activeMenu: '/manage/oa/user',
          },
        },
        {
          path: 'edit/:id',
          name: 'AuthorityManageUserEdit',
          component: () => import('@/views/oa/user/detail.vue'),
          meta: {
            title: '编辑账号',
            sidebar: false,
            activeMenu: '/manage/oa/user',
          },
        },
      ],
    },
    {
      path: 'usergroup',
      name: 'AuthorityManageUserGroup',
      meta: {
        title: '用户组管理',
        icon: 'ph:user',
      },
      children: [
        {
          path: '',
          name: 'AuthorityManageUserGroupList',
          component: () => import('@/views/oa/user_group/list.vue'),
          meta: {
            title: '用户组列表',
            sidebar: false,
            cache: ['AuthorityManageUserGroupCreate', 'AuthorityManageUserGroupEdit'],
          },
        },
        {
          path: 'create',
          name: 'AuthorityManageUserGroupCreate',
          component: () => import('@/views/oa/user_group/detail.vue'),
          meta: {
            title: '新增用户组',
            sidebar: false,
            activeMenu: '/manage/oa/userGroup',
          },
        },
        {
          path: 'edit/:id',
          name: 'AuthorityManageUserGroupEdit',
          component: () => import('@/views/oa/user_group/detail.vue'),
          meta: {
            title: '编辑用户组',
            sidebar: false,
            activeMenu: '/manage/oa/userGroup',
          },
        },
      ],
    },
    {
      path: 'department',
      name: 'AuthorityManageDepartment',
      redirect: '/manage/oa/department',
      meta: {
        title: '部门管理',
        // i18n: 'route.general.department.root',
        icon: 'clarity:organization-line',
      },
      children: [
        {
          path: '',
          name: 'AuthorityManageDepartmentList',
          component: () => import('@/views/oa/department/list.vue'),
          meta: {
            title: '部门列表',
            // i18n: 'route.general.department.list',
            sidebar: false,
            cache: ['AuthorityManageDepartmentCreate', 'AuthorityManageDepartmentEdit'],
          },
        },
        {
          path: 'detail',
          name: 'AuthorityManageDepartmentCreate',
          component: () => import('@/views/oa/department/detail.vue'),
          meta: {
            title: '新增部门',
            // i18n: 'route.general.department.create',
            sidebar: false,
            activeMenu: '/manage/oa/department',
          },
        },
        {
          path: 'detail/:id',
          name: 'AuthorityManageDepartmentEdit',
          component: () => import('@/views/oa/department/detail.vue'),
          meta: {
            title: '编辑部门',
            // i18n: 'route.general.department.edit',
            sidebar: false,
            activeMenu: '/manage/oa/department',
          },
        },
        {
          path: '/:departmentId/job/:departmentName',
          name: 'AuthorityManageJob',
          meta: {
            title: '职位管理',
            // i18n: 'route.general.department.job.root',
            sidebar: false,
            icon: 'mdi:user-tie',
          },
          children: [
            {
              path: '',
              name: 'AuthorityManageJobList',
              component: () => import('@/views/oa/department/job/list.vue'),
              meta: {
                title: '职位列表',
                // i18n: 'route.general.department.job.list',
                sidebar: false,
                activeMenu: '/manage/oa/department',
                cache: ['AuthorityManageJobCreate', 'AuthorityManageJobEdit'],
              },
            },
            {
              path: 'detail',
              name: 'AuthorityManageJobCreate',
              component: () => import('@/views/oa/department/job/detail.vue'),
              meta: {
                title: '新增职位',
                // i18n: 'route.general.department.job.create',
                sidebar: false,
                activeMenu: '/manage/oa/department',
              },
            },
            {
              path: 'detail/:id',
              name: 'AuthorityManageJobEdit',
              component: () => import('@/views/oa/department/job/detail.vue'),
              meta: {
                title: '编辑职位',
                // i18n: 'route.general.department.job.edit',
                sidebar: false,
                activeMenu: '/manage/oa/department',
              },
            },
          ],
        },
      ],
    },
  ],
}

export default routes
