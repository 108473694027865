import axios from 'axios'

// import qs from 'qs'
import { ElMessage } from 'element-plus'
import useSettingsStore from '@/store/modules/settings'
import useUserStore from '@/store/modules/user'

// 创建 axios 实例
const api = axios.create({
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
  timeout: 1000 * 60,
  responseType: 'json',
})

// 配置请求拦截器
api.interceptors.request.use(
  (request) => {
    // 全局拦截请求发送前提交的参数
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    // 设置请求头
    if (request.headers) {
      // 请求头配置语言
      request.headers['Accept-Language'] = settingsStore.settings.app.defaultLang
      // 判断是否登录 如果登录则设置请求头Token
      if (userStore.isLogin) {
        request.headers['Session-Token'] = userStore.token
      }
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
      // request.data = qs.stringify(request.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    return request
  },
)

// 配置响应拦截器
api.interceptors.response.use(
  (response) => {
    /**
     * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
     * 后端返回数据格式为：{ code: '200', message: 'ok', data: {} }
     * 参见问题使用http状态码，当 code 为 200 时表示请求成功，为 403 时表示接口需要登录或者登录状态失效，需要重新登录
     * 请求出错时 error 会返回错误信息
     */
    if (response.data.code !== '200') {
      switch (response.data.code) {
        case '500':
          // 后端错误
        // 这里做错误提示，如果使用了 element plus 则可以使用 Message 进行提示
          ElMessage.error(response.data.message)
          break
        case '403':
          // 用户没有登录或者登录状态失效，需要重新登录
          useUserStore().logout()
          ElMessage.warning(response.data.message)
          break
        case '400':
          ElMessage.warning(response.data.message)
          break
        default:
          // 其他业务错误
          ElMessage({
            message: response.data.message,
            type: 'warning',
          })
          break
      }
      // 返回拒绝原因 此处返回之后后续逻辑不会执行
      return Promise.reject(response.data)
    }
    // 成功返回数据
    return Promise.resolve(response.data)
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    }
    else if (message.includes('timeout')) {
      message = '接口请求超时'
    }
    else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    }
    ElMessage({
      message,
      type: 'error',
    })
    return Promise.reject(error)
  },
)

export default api
