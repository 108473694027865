import Mock from 'mockjs'

const AllList: any[] = []
for (let i = 0; i < 50; i++) {
  AllList.push(Mock.mock({
    id: '@id',
    defaultName: '@ctitle(2, 4)',
    defaultIconPath: 'https://th.bing.com/th?id=ODLS.2e71af61-6a5a-4385-b77e-f1eab9a9f6d8&w=32&h=32&qlt=94&pcl=fffffa&o=6&pid=',
    languages: [{
      id: 1,
      name: 'cn',
      isDefault: true,
    }, {
      id: 2,
      name: 'en',
      isDefault: false,
    }],
    count: '@integer(0,100)',
    state: '@boolean',
  }))
}

export default [
  {
    url: '/mock/watch_faces/type/list',
    method: 'get',
    response: (option: any) => {
      const { defaultName, from, limit } = option.query
      const list = AllList.filter((item) => {
        return defaultName ? item.defaultName.includes(defaultName) : true
      })
      const pageList = list.filter((item, index) => {
        return index >= ~~from && index < (~~from + ~~limit)
      })
      return {
        message: 'ok',
        code: '200',
        data: {
          list: pageList,
          total: list.length,
        },
      }
    },
  },
  {
    url: '/mock/watch_faces/type/detail',
    method: 'get',
    response: (option: any) => {
      const info = AllList.filter(item => item.id === option.query.id)
      return {
        message: 'ok',
        code: '200',
        data: info[0],
      }
    },
  },
  {
    url: '/mock/watch_faces/type/create',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/watch_faces/type/edit',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
  {
    url: '/mock/watch_faces/type/delete',
    method: 'post',
    response: {
      message: 'ok',
      code: '200',
      data: {
        isSuccess: true,
      },
    },
  },
]
