import { defaultsDeep } from 'lodash-es'
import type { RecursiveRequired, Settings } from '#/global'
import settingsDefault from '@/settings.default'

const globalSettings: Settings.all = {
  // 版权信息
  copyright: {
    enable: true, // 是否开启底部版权，同时在路由 meta 对象里可以单独设置某个路由是否显示底部版权信息
    dates: '2019-2023', // 网站运行日期
    company: '玉成创新', // 公司名称
    website: 'http://www.ycaviation.com', // 网站地址
    beian: '粤ICP备15084417号', // 网站备案号
  },
  app: {
    enablePermission: false,
    enableDynamicTitle: true,
    enableErrorLog: true,
    enableAppSetting: true,
    theme: 'default',
    colorScheme: 'light',
    elementSize: 'default',
    defaultLang: 'zh-cn',
    enableProgress: true,
    storagePrefix: 'fa_',
    enableWatermark: false,
    routeBaseOn: 'frontend',
    enableUserPreferences: false,
  },
  layout: {
    enableMobileAdaptation: true,
    widthMode: 'adaption',
  },
  menu: {
    menuFillStyle: 'radius',
    menuActiveStyle: '',
    enableSubMenuCollapseButton: true,
    enableHotkeys: true,
    baseOn: 'frontend',
    menuMode: 'side',
    switchMainMenuAndPageJump: false,
    subMenuUniqueOpened: true,
    subMenuOnlyOneHide: false,
    subMenuCollapse: false,
  },
  topbar: {
    mode: 'fixed',
    switchTabbarAndToolbar: false,
  },
  tabbar: {
    enable: true,
    enableIcon: true,
    enableMemory: true,
    enableHotkeys: true,
    style: 'fashion',
    mergeTabsBy: '',
    storageTo: 'local',
  },
  toolbar: {
    enableNotification: true,
    enableI18n: true,
    enableFullscreen: true,
    enablePageReload: true,
    enableColorScheme: true,
  },
  favorites: {
    enable: true,
    storageTo: 'local',
  },
  breadcrumb: {
    style: 'modern',
    enableMainMenu: true,
    enable: true,
  },
  mainPage: {
    enableHotkeys: true,
    iframeCacheMax: 9,
    transitionMode: 'slide-right',
    enableTransition: true,
  },
  home: {
    enable: true,
    title: '主页',
  },
  navSearch: {
    enable: true,
    enableHotkeys: true,
  },
}

export default defaultsDeep(globalSettings, settingsDefault) as RecursiveRequired<Settings.all>
